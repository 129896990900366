<template>
  <div class="wrap-dynainfo" v-loading="loading">
    <!-- <el-empty description="动态信息功能维护中"></el-empty> -->
    <template>
      <list-item v-for="(item, index) in list" :key="item.item + index" :info="item" />
    </template>
    <div style="margin: 48px 0 24px 0;">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="pageNo" :page-size="pageSize"
        layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import listItem from "../components/public/listitem.vue";
import { getData } from "../utils/homePublicMethods";

export default {
  components: {
    listItem,
  },
  data() {
    return {
      pageNo: 1,
      pageSize:10,
      total: 10,
      loading : false,
      list: [
        {
          title: "",
          source: "",
          time: "",
          content: "",
          url: "",
        },
      ],
    };
  },
  methods:{
    handleCurrentChange(val){
      this.loading = true
      getData('1', this.list, null, val).finally(()=>{
        this.loading = false
      })
    },
    setTotal(total){
      this.total = total
    }
  },
  mounted() {
    this.loading = true
      getData('1', this.list, null, this.pageNo, this.setTotal).finally(()=>{
        this.loading = false
      })
  },
};
</script>

<style  scoped>
.wrap-dynainfo {
  background: rgba(245, 245, 245, 0.3);
  padding: 0 0 24px 0;
}

</style>
